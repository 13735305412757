import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import {
  ArticleListFragment,
  CitationSliderFragment,
  HomepageTextContentWithMediaFragment,
  HomepageQuery,
  ImageSliderFragment,
  InstagramStoriesFragment,
  LargeProgramFragment,
  SimpleImageCardListFragment,
  ArticleLinkFragment,
} from '../../graphql-types';
import { HomepageTemplate, HomepageSection } from '../templates/homepage.template';
import { AvailableLocales, parseLocale } from '../locale';
import {
  mapQuoteSliderFragmentToSliderProps,
  mapImageSliderFragmentToSliderProps,
} from '../data-mappers/slider.mapper';
import {
  mapFragmentToContentImageProps,
  mapFragmentToEditorialContentListProps,
  mapFragmentToInstagramStoriesProps,
  mapFragmentToSimpleCardListProps,
  mapFragmentToTitleCtaProps,
  mapLargeProgramFragmentToMediaCardProps,
} from '../data-mappers/homepage.mapper';
import SEO from '../components/seo';
import { identity, safeMap } from '../data-mappers/utils';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query Homepage($language: String) {
    cms {
      articles(limit: 3, locale: $language, sort: "created_at:desc") {
        ...ArticleLink
      }
      homepage(locale: $language) {
        metas {
          id
          description
        }
        title
        Section {
          ...SimpleImageCardList
          ...HomepageTextContentWithMedia
          ...InstagramStories
          ...ArticleList
          ...LargeProgram
          ...ImageSlider
          ...CitationSlider
        }
      }
    }
  }
`;

type SectionFragment =
  | CitationSliderFragment
  | SimpleImageCardListFragment
  | ImageSliderFragment
  | LargeProgramFragment
  | ArticleListFragment
  | InstagramStoriesFragment
  | HomepageTextContentWithMediaFragment;

const Homepage: React.FC<{ data: HomepageQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: '' },
      { locale: 'fr', slug: '' },
    ],
  });

  const title = data.cms.homepage?.title ?? '';
  const latestArticles = safeMap(identity, data.cms.articles);

  const sections = safeMap(fragment => fragmentToSection(fragment, latestArticles, locale), data.cms.homepage?.Section);

  return (
    <>
      <SEO lang={locale} title={title} description={data.cms.homepage?.metas?.description ?? ''} />
      <HomepageTemplate sections={sections} />
    </>
  );
};

function fragmentToSection(
  section: SectionFragment,
  latestArticles: ArticleLinkFragment[],
  locale: AvailableLocales
): HomepageSection {
  switch (section.__typename) {
    case 'cms_ComponentHomepageInstagramStories':
      return {
        type: 'instagram',
        stories: mapFragmentToInstagramStoriesProps(section, locale),
      };

    case 'cms_ComponentHomepageTextWithImage':
      return {
        type: 'contentimage',
        card: mapFragmentToContentImageProps(section, locale),
      };

    case 'cms_ComponentHomepageLargeProgram':
      return {
        type: 'largeprogram',
        title: mapFragmentToTitleCtaProps(section),
        card: mapLargeProgramFragmentToMediaCardProps(section, locale),
      };

    case 'cms_ComponentHomepageArticleList':
      return {
        type: 'news',
        title: mapFragmentToTitleCtaProps(section),
        content: mapFragmentToEditorialContentListProps(latestArticles, locale),
      };

    case 'cms_ComponentHomepageSimpleImageCardList':
      return {
        type: 'simplecard',
        title: mapFragmentToTitleCtaProps(section),
        card: mapFragmentToSimpleCardListProps(section, locale),
      };

    case 'cms_ComponentHomepageImageSlider':
      return {
        type: 'slider',
        slides: mapImageSliderFragmentToSliderProps(section, locale),
      };

    case 'cms_ComponentHomepageCitationSlider':
      return {
        type: 'sliderquote',
        slides: mapQuoteSliderFragmentToSliderProps(section, locale),
      };
  }
}

export default Homepage;
